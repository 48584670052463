import * as React from 'react'
import {
  ContentBlockTitle,
  ContentBlockWrapper,
  HeadingWrapper,
} from '../molecules/content-blocks'
import { ContentfulStoriesBlock } from '../../types/contentBlocks'
import Slider from 'react-slick'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image'
import Button from '../molecules/button'
import { getIconSrc } from '../../helpers/getIconSrc'
import theme from '../atoms/theme'
import { Pager } from '../molecules/carousel'
import { useRef, useState } from 'react'

const ArrowSrc = require('../../assets/carousel-arrow.svg') as string

interface ArrowProps {
  disabled?: boolean
}

const Arrow = styled.img`
  cursor: ${(props: ArrowProps): string =>
    props.disabled ? 'default' : 'pointer'};
  width: 4rem;
  height: 4rem;
  z-index: 2;
  opacity: ${(props: ArrowProps): string => (props.disabled ? '0.2' : '1')};
`

const PrevArrow = styled(Arrow)`
  transform: rotate(180deg);
`

type StoryBlockProps = {
  block: ContentfulStoriesBlock
}

const StyledSlider = styled(Slider)`
  display: grid;
  user-select: text;

  .slick-active {
    z-index: 1;
  }
`

const SlideWrapper = styled.div``

const Column = styled.div``

const Slide = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem;

  ${Column}:nth-child(1) {
    order: 2;
  }

  ${Column}:nth-child(2) {
    order: 1;
  }

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 6rem;

    ${Column}:nth-child(1) {
      order: 1;
    }

    ${Column}:nth-child(2) {
      order: 2;
    }
  }
`

const StyleImg = styled(Img)`
  border-radius: 2rem;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    flex-direction: row;
  }
`

const Icon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
`

const ContentWrapper = styled.div`
  margin-bottom: 4rem;

  ${SlideWrapper} & p {
    font-size: 1.8rem;
    margin-top: 0;
    margin-bottom: 2rem;
    color: ${theme.colours.offBlack};
  }
`

const NavWrapper = styled.div`
  margin-top: 4rem;

  &.slick-dots {
    position: relative;
    bottom: 0;
  }
`

const Nav = styled.div``

const NavDots = styled.div`
  height: 2rem;
  margin-top: 1rem;
  flex-shrink: 0;
`

const NavArrows = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-shrink: 0;
`

interface SliderType {
  current: {
    slickNext: () => void
    slickPrev: () => void
  }
}

const StoriesBlock = (props: StoryBlockProps): JSX.Element => {
  const { block } = props
  const { title, stories } = block

  const [currentSlide, setCurrentSlide] = useState(0)
  const slider: SliderType = useRef()
  const handleArrow = (direction: string): void => {
    if (slider && slider.current) {
      switch (direction) {
        case 'next':
          slider.current.slickNext()
          break
        case 'prev':
          slider.current.slickPrev()
          break
        default:
          break
      }
    }
  }

  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    pauseOnHover: true,
    lazyLoad: 'progressive',
    autoplay: false,
    autoplaySpeed: 6000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next): void => {
      setCurrentSlide(next)
    },
    customPaging: (i): JSX.Element => {
      return <Pager active={i === currentSlide} />
    },
    appendDots: (dots): JSX.Element => {
      // get the next slides title. If there is no next slide, get the first slides title
      return (
        <NavWrapper>
          <Nav>
            <NavArrows>
              <PrevArrow
                onClick={(): void => handleArrow('prev')}
                src={ArrowSrc}
                alt="Previous"
              />
              <NavDots>{dots}</NavDots>
              <Arrow
                onClick={(): void => handleArrow('next')}
                src={ArrowSrc}
                alt="Next"
              />
            </NavArrows>
          </Nav>
        </NavWrapper>
      )
    },
  }

  return (
    <ContentBlockWrapper>
      <HeadingWrapper>
        <ContentBlockTitle>{title}</ContentBlockTitle>
      </HeadingWrapper>
      <StyledSlider {...settings} ref={slider}>
        {stories.map((story, index) => (
          <SlideWrapper key={index}>
            <Slide>
              <Column>
                <ContentWrapper>
                  {documentToReactComponents(story.description.json)}
                </ContentWrapper>
                <ButtonsWrapper>
                  {story.ctaButtonLabel && story.ctaButtonUrl && (
                    <Button href={story.ctaButtonUrl}>
                      {story.ctaButtonLabel}
                    </Button>
                  )}
                  {story.videoButtonLabel && story.videoButtonUrl && (
                    <Button href={story.videoButtonUrl} visualType={'Outline'}>
                      {story.videoButtonLabel}{' '}
                      <Icon src={getIconSrc('Video')} alt="Bar Graph Icon" />
                    </Button>
                  )}
                </ButtonsWrapper>
              </Column>
              <Column>
                <StyleImg fluid={story.image.fluid} alt={story.image.title} />
              </Column>
            </Slide>
          </SlideWrapper>
        ))}
      </StyledSlider>
    </ContentBlockWrapper>
  )
}

export default StoriesBlock
