import * as React from 'react'
import PageHeroSlider from '../organisms/page-hero-slider'
import InfoBlock from '../organisms/info-block'
import ContentSetBlock from '../organisms/content-set-block'
import StoriesBlock from '../organisms/stories-block'
import EventsBlock from '../organisms/events-block'
import CtaBlock from '../organisms/cta-block'
import styled from 'styled-components'
import theme from '../../components/atoms/theme'
import {
  Block,
  ContentfulContentSetBlock,
  ContentfulCtaBlock,
  ContentfulEventsBlock,
  ContentfulInfoBlock,
  ContentfulSliderBlock,
  ContentfulStoriesBlock,
} from '../../types/contentBlocks'

interface ContentBlockProps {
  blocks: Block[]
}

export const ContentBlockWrapper = styled.div`
  margin: 7.5rem 0 0 0;

  p {
    font-size: 2rem;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 4.4rem;
      margin-bottom: 5.5rem;

      &:after {
        content: '';
        height: 1.6rem;
        width: 1.6rem;
        background: ${theme.colours.quaternary};
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 0.8rem;
        left: 0;
      }
    }
  }
`

export const ContentBlockTitle = styled.h2`
  font-size: 4rem;
  color: ${theme.colours.secondary};
  font-weight: 900;
  font-family: ${theme.fonts.primaryBlack};
  line-height: 140%;
  margin-bottom: 1.6rem;
`

export const ContentBlockDescription = styled.div`
  font-size: 2.4rem;
  line-height: 140%;

  p {
    font-size: 2.4rem;
    line-height: 140%;
  }
`

export const HeadingWrapper = styled.div`
  text-align: center;
  margin-bottom: 6rem;
`

const ContentBlocks = (props: ContentBlockProps): JSX.Element => (
  <div>
    {props.blocks.map((block: Block) => {
      if (
        (block as ContentfulSliderBlock).__typename === 'ContentfulSliderBlock'
      ) {
        const sliderBlock = block as ContentfulSliderBlock
        return (
          <PageHeroSlider slides={sliderBlock.slides} key={sliderBlock.id} />
        )
      }
      if ((block as ContentfulInfoBlock).__typename === 'ContentfulInfoBlock') {
        const infoBlock = block as ContentfulInfoBlock
        return <InfoBlock block={infoBlock} key={infoBlock.id} />
      }
      if (
        (block as ContentfulContentSetBlock).__typename ===
        'ContentfulContentSetBlock'
      ) {
        const contentSetBlock = block as ContentfulContentSetBlock
        return (
          <ContentSetBlock block={contentSetBlock} key={contentSetBlock.id} />
        )
      }
      if (
        (block as ContentfulStoriesBlock).__typename ===
        'ContentfulStoriesBlock'
      ) {
        const storiesBlock = block as ContentfulStoriesBlock
        return <StoriesBlock block={storiesBlock} key={storiesBlock.id} />
      }
      if (
        (block as ContentfulEventsBlock).__typename === 'ContentfulEventsBlock'
      ) {
        const eventsBlock = block as ContentfulEventsBlock
        return <EventsBlock block={eventsBlock} key={eventsBlock.id} />
      }
      if ((block as ContentfulCtaBlock).__typename === 'ContentfulCtaBlock') {
        const ctaBlock = block as ContentfulCtaBlock
        return <CtaBlock block={ctaBlock} key={ctaBlock.id} />
      }
      if ('__typename' in block) {
        console.warn(`Unhandled block type: ${block?.__typename}`)
      } else {
        console.warn(`Unknown block type`)
      }
      return null
    })}
  </div>
)

export default ContentBlocks
