import * as React from 'react'
import {
  ContentBlockDescription,
  ContentBlockTitle,
  ContentBlockWrapper,
  HeadingWrapper,
} from '../molecules/content-blocks'
import { ContentfulEventsBlock } from '../../types/contentBlocks'
import styled from 'styled-components'
import Img from 'gatsby-image'
import theme from '../atoms/theme'
import Button from '../molecules/button'
import { getIconSrc } from '../../helpers/getIconSrc'

type EventsBlockProps = {
  block: ContentfulEventsBlock
}

const formatEventDates = (startDate: string, endDate?: string): string => {
  // Create a date object from the startDate string
  const start = new Date(startDate)

  // Formatter for full date (e.g., 1 Apr 2024)
  const dateFormatter = new Intl.DateTimeFormat('en-NZ', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })

  // Formatter for time (e.g., 10:00 AM)
  const timeFormatter = new Intl.DateTimeFormat('en-NZ', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  // Format the start time
  const startTime = timeFormatter.format(start)

  if (endDate) {
    // Create a date object from the endDate string if it exists
    const end = new Date(endDate)

    // Check if the event spans multiple days
    const sameDay = start.toDateString() === end.toDateString()
    const endTime = timeFormatter.format(end)

    if (sameDay) {
      // For same-day events, format like: 1 Apr 2024 | 10:00 AM - 4:00 PM
      return `${dateFormatter.format(start)} | ${startTime} - ${endTime}`
    } else {
      // For multi-day events, format like: 1 Apr - 30 Apr 2024 | 10:00 AM - 4:00 PM
      return `${dateFormatter.format(start)} - ${dateFormatter.format(
        end
      )} | ${startTime} - ${endTime}`
    }
  } else {
    // If no endDate is given, return just the start date and time
    return `${dateFormatter.format(start)} | ${startTime}`
  }
}

const EventWrapper = styled.div`
  display: grid;
  border-radius: 2rem;
  overflow: hidden;

  background-color: ${theme.colours.secondary};
  margin-bottom: 2rem;

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    grid-template-columns: 40% 60%;
  }
`

const ImageWrapper = styled(Img)`
  height: 26.6rem;
  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    height: auto;
  }
`

const ContentWrapper = styled.div`
  color: ${theme.colours.white};
  padding: 2.8rem;

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    padding: 4rem 2.8rem;
  }
`

const EventDescription = styled.div`
  font-size: 1.6rem;
  white-space: pre-line;
  margin-bottom: 3rem;
`

const ButtonsWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    flex-direction: row;
  }
`

const Meta = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;

  strong {
    font-family: ${theme.fonts.primaryBold};
  }
`

const Icon = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
`

const EventsBlock = (props: EventsBlockProps): JSX.Element => {
  const { block } = props
  const { title, description, events } = block

  return (
    <ContentBlockWrapper>
      <HeadingWrapper>
        <ContentBlockTitle>{title}</ContentBlockTitle>
        <ContentBlockDescription>
          {description.description}
        </ContentBlockDescription>
      </HeadingWrapper>
      {events.map((event, index) => (
        <EventWrapper key={index}>
          <ImageWrapper fluid={event.image.fluid} />
          <ContentWrapper>
            <h3>{event.title}</h3>
            <EventDescription>{event.description.description}</EventDescription>
            <Meta>
              <Icon src={getIconSrc('Location Pin')} alt="Location" />
              <div>{event.locationName}</div>
            </Meta>
            <Meta>
              <strong>
                {formatEventDates(event.startDate, event.endDate)}
              </strong>
            </Meta>
            {(event.url || event.subscribeLink) && (
              <ButtonsWrapper>
                {event.url && <Button href={event.url}>View Details</Button>}
                {event.subscribeLink && (
                  <Button href={event.subscribeLink} visualType={'Outline'}>
                    Register Now
                  </Button>
                )}
              </ButtonsWrapper>
            )}
          </ContentWrapper>
        </EventWrapper>
      ))}
    </ContentBlockWrapper>
  )
}

export default EventsBlock
