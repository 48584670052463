import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ContentBlocks from '../components/molecules/content-blocks'

class RootIndex extends React.Component {
  render(): JSX.Element {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const page = get(this, 'props.data.contentfulPage')

    return (
      <Layout>
        <Helmet title={siteTitle} />
        <Helmet>
          <meta
            name="google-site-verification"
            content="KxzHHxPTYUBNF8kTL0ZNNV8B1OikoDidcecqx3Upihk"
          />
        </Helmet>
        <ContentBlocks blocks={page.contentBlocks} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: "/" }) {
      title
      contentBlocks {
        ... on Node {
          ... on ContentfulSliderBlock {
            ...ContentfulSliderBlockFragment
          }
          ... on ContentfulInfoBlock {
            ...ContentfulInfoBlockFragment
          }
          ... on ContentfulContentSetBlock {
            ...ContentfulContentSetBlockFragment
          }
          ... on ContentfulStoriesBlock {
            ...ContentfulStoriesBlockFragment
          }
          ... on ContentfulEventsBlock {
            ...ContentfulEventsBlockFragment
          }
          ... on ContentfulCtaBlock {
            ...ContentfulCtaBlockFragment
          }
        }
      }
    }
  }
`
