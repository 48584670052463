const barGraphSrc = require('../assets/icons/bar_graph.svg') as string
const lineGraphSrc = require('../assets/icons/line_graph.svg') as string
const groupOfPeopleSrc = require('../assets/icons/group_of_people.svg') as string
const columnsSrc = require('../assets/icons/columns.svg') as string
const handshakeSrc = require('../assets/icons/handshake.svg') as string
const locationSrc = require('../assets/icons/location.svg') as string
const videoSrc = require('../assets/icons/video.svg') as string
const locationPin = require('../assets/icons/location_pin.svg') as string

export const getIconSrc = (title: string): string => {
  const iconMap: { [key: string]: string } = {
    'Bar Graph': barGraphSrc,
    'Line Graph': lineGraphSrc,
    'Group of People': groupOfPeopleSrc,
    Columns: columnsSrc,
    Handshake: handshakeSrc,
    Location: locationSrc,
    Video: videoSrc,
    'Location Pin': locationPin,
  }

  return iconMap[title as keyof typeof iconMap]
}
