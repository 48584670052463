import * as React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import theme from '../atoms/theme'
import getFocalRatio from '../../helpers/getFocalRatio'
import Button, {
  ButtonVisualStyle,
  ButtonVisualType,
} from '../molecules/button'
import { Pager } from '../molecules/carousel'
import { useRef, useState } from 'react'

const ArrowSrc = require('../../assets/carousel-arrow.svg') as string

interface ArrowProps {
  disabled?: boolean
}

const Arrow = styled.img`
  cursor: ${(props: ArrowProps): string =>
    props.disabled ? 'default' : 'pointer'};
  width: 4rem;
  height: 4rem;
  z-index: 2;
  opacity: ${(props: ArrowProps): string => (props.disabled ? '0.2' : '1')};
`

const PrevArrow = styled(Arrow)`
  transform: rotate(180deg);
`

const StyledSlider = styled(Slider)`
  display: grid;
  user-select: text;

  .slick-active {
    z-index: 1;
  }
`

export const FullWidthContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

const HeroSliderContainer = styled.div`
  width: 100%;
  position: relative;
  height: calc(50vh - 7rem);
  min-height: 60rem;
  max-height: 72.5rem;
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    height: calc(50vh - 9rem);
    min-height: 66rem;
    max-height: 89.4rem;
  }
`

const HeroImage = styled.img`
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 144rem;
  padding: 0 2.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`

const Content = styled.div`
  max-width: 96rem;
  margin-bottom: 9.8rem;
  @media only screen and ${theme.breakpoints.fromMediumScreen} {
  }
`

const Title = styled.h1`
  color: ${theme.colours.white};
  font-size: 3.6rem;
  line-height: 4.5rem;
  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    font-size: 6rem;
    line-height: 6.4rem;
  }
`

const Description = styled.p`
  font-size: 1.8rem;
  color: ${theme.colours.white};
`

const Overlay = styled.div`
  background: linear-gradient(62.46deg, #022b55 0%, rgba(2, 43, 85, 0) 68.01%);
  background-blend-mode: multiply;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Buttons = styled.div`
  position: relative;
`

// TODO: restyle buttons as per design
const StyledButton = styled(Button)`
  margin-right: 1.6rem;
  margin-bottom: 1.6rem;
  width: auto;
`

const NavWrapper = styled.div`
  bottom: 0;
  @media only screen and ${theme.breakpoints.fromSmallScreen} {
    padding: 0 2.75rem;
  }
`

const Nav = styled.div`
  margin: 0 auto;
  max-width: 138rem;
  background-color: ${theme.colours.white};
  border-radius: 2rem 2rem 0 0;
  padding: 0 3.2rem;
  display: flex;
  align-items: center;
  height: 8.8rem;
  justify-content: center;
  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    justify-content: space-between;
  }
`

const NavDots = styled.div`
  height: 2rem;
  margin-top: 1rem;
  flex-shrink: 0;
`

const MobileOnly = styled.div`
  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    display: none;
  }
`

const DesktopOnly = styled.div`
  display: none;
  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    display: block;
  }
`

const NavNext = styled.div`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: ${theme.colours.darkGrey};
`

const NavArrows = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  flex-shrink: 0;
`

type PageHeroSliderProps = {
  slides: Slide[]
}

interface SliderType {
  current: {
    slickNext: () => void
    slickPrev: () => void
  }
}

const PageHeroSlider = (props: PageHeroSliderProps): JSX.Element => {
  const { slides } = props
  const [currentSlide, setCurrentSlide] = useState(0)
  const slider: SliderType = useRef()
  const handleArrow = (direction: string): void => {
    if (slider && slider.current) {
      switch (direction) {
        case 'next':
          slider.current.slickNext()
          break
        case 'prev':
          slider.current.slickPrev()
          break
        default:
          break
      }
    }
  }
  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    pauseOnHover: true,
    lazyLoad: 'progressive',
    autoplay: false,
    autoplaySpeed: 6000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next): void => {
      setCurrentSlide(next)
    },
    customPaging: (i): JSX.Element => {
      return <Pager active={i === currentSlide} />
    },
    appendDots: (dots): JSX.Element => {
      // get the next slides title. If there is no next slide, get the first slides title
      const nextTitle = slides[currentSlide + 1]
        ? slides[currentSlide + 1].title
        : slides[0].title
      return (
        <NavWrapper>
          <Nav>
            <NavDots>
              <DesktopOnly>{dots}</DesktopOnly>
            </NavDots>
            <NavNext>
              <DesktopOnly>NEXT: {nextTitle}</DesktopOnly>
            </NavNext>

            <NavArrows>
              <PrevArrow
                onClick={(): void => handleArrow('prev')}
                src={ArrowSrc}
                alt="Previous"
              />
              <MobileOnly>
                <NavDots>{dots}</NavDots>
              </MobileOnly>
              <Arrow
                onClick={(): void => handleArrow('next')}
                src={ArrowSrc}
                alt="Next"
              />
            </NavArrows>
          </Nav>
        </NavWrapper>
      )
    },
  }

  return (
    <FullWidthContainer>
      {slides && slides.length > 0 && (
        <StyledSlider {...settings} ref={slider}>
          {slides.map((slide, index) => {
            const { buttons } = slide
            const focalPoint = (slide.backgroundImage &&
              getFocalRatio(slide.backgroundImage)) || { x: 50, y: 50 }
            return (
              slide?.backgroundImage?.image?.fluid?.src && (
                <HeroSliderContainer key={index}>
                  <HeroImage
                    src={slide.backgroundImage.image.fluid.src}
                    srcSet={slide.backgroundImage.image.fluid.srcSetWebp}
                    alt={slide.backgroundImage.image.title}
                    style={{
                      objectPosition: `${focalPoint.x}% ${focalPoint.y}%`,
                    }}
                  />
                  <Overlay />
                  <Container>
                    <Content>
                      {slide.title && <Title>{slide.title}</Title>}
                      {slide.body?.body && (
                        <Description>{slide.body.body}</Description>
                      )}
                      {/* Render the buttons  */}
                      {buttons && buttons.length > 0 && (
                        <Buttons>
                          {buttons.map((button, index) => {
                            return (
                              <a href={button.url} key={index}>
                                <StyledButton
                                  visualType={button.type as ButtonVisualType}
                                  visualStyle={
                                    button.style as ButtonVisualStyle
                                  }
                                >
                                  {button.label}
                                </StyledButton>
                              </a>
                            )
                          })}
                        </Buttons>
                      )}
                    </Content>
                  </Container>
                </HeroSliderContainer>
              )
            )
          })}
        </StyledSlider>
      )}
    </FullWidthContainer>
  )
}

export default PageHeroSlider
